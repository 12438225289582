// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/auth.login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/auth.login.tsx");
  import.meta.hot.lastModified = "315532801000";
}
// REMIX HMR END

import { AuthPage } from '@entrycall/route-auth-frontend';
import { authAction } from '@entrycall/route-auth-backend';
import { redirect } from '@remix-run/node';
import { cookies } from '../server/cookies/cookies.server';
import { API_CONFIG } from '@entrycall/lib-api-server';
export const loader = async ({
  request
}) => {
  const url = new URL(request.url);
  if (url.searchParams.get('resend')) {
    if (url.searchParams.get('method') && url.searchParams.get('data')) {
      try {
        //* After successful resend, redirect to the verification route
        return redirect(`${API_CONFIG.WEBSITE_ROUTES.AUTHENTICATION.VERIFY_ROUTE.path}?${url.searchParams.get('method')}=${encodeURIComponent(`${url.searchParams.get('data')}`)}`);
      } catch (error) {
        console.error('Error resending verification code:', error);
        return null;
      }
    } else {
      throw new Error('Invalid input: both method and data must be provided in the URL');
    }
  }

  //* If not resending, proceed with the normal login flow
  const {
    token,
    refreshToken
  } = await cookies.parseAllCookies(request);
  if (token && refreshToken) {
    return redirect(API_CONFIG.WEBSITE_ROUTES.DASHBOARD_ROUTE.path);
  }
  return null;
};
export const action = request => {
  return authAction(request);
};
const Login = () => {
  return <AuthPage />;
};
_c = Login;
export default Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;